export default class Tabs {
    constructor(el) {
        this.init();
    }

    init() {
        const myTabs = document.querySelectorAll("ul.nav-tabs > li");

        function myTabClicks(tabClickEvent) {
            let i;
            for (i = 0; i < myTabs.length; i++) {
                myTabs[i].classList.remove("active");
            }
            const clickedTab = tabClickEvent.currentTarget;
            clickedTab.classList.add("active");
            tabClickEvent.preventDefault();
            const myContentPanes = document.querySelectorAll(".tab-pane");
            for (i = 0; i < myContentPanes.length; i++) {
                myContentPanes[i].classList.remove("active");
            }
            const anchorReference = tabClickEvent.target;
            const activePaneId = anchorReference.getAttribute("href");
            const activePane = document.querySelector(activePaneId);
            activePane.classList.add("active");
        }

        for (let i = 0; i < myTabs.length; i++) {
            myTabs[i].addEventListener("click", myTabClicks);
        }

        if (document.querySelector('.template-our-network')) {
            const urlParams = new URLSearchParams(window.location.search);
            let queryString = urlParams.get('cible');

            if (queryString) {
                document.querySelector('.template-our-network .nav-tabs > li a[href="#' + queryString + '"]').click();
                window.scrollTo({
                    top: document.querySelector('.template-our-network .nav-tabs > li a[href="#' + queryString + '"]').offsetTop - 50,
                    behavior: "smooth"
                });
            }

            if (document.querySelector('select[name="choice-tab"]')) {
                document.querySelector('select[name="choice-tab"]').addEventListener("change", function () {
                    document.querySelector('.template-our-network .nav-tabs > li a[href="' + this.value + '"]').click();
                });
            }
        }

        if (document.querySelector('.template-where-are-we')) {
            const urlParams = new URLSearchParams(window.location.search);
            let queryString = urlParams.get('vue-choice');

            document.getElementById('choice-map').addEventListener("click", function () {
                document.querySelector('input[name="vue-choice"]').value = "tab-1";
            });

            document.getElementById('choice-list').addEventListener("click", function () {
                document.querySelector('input[name="vue-choice"]').value = "tab-2";
            });

            if (queryString) {
                document.querySelector('.template-where-are-we .nav-tabs > li a[href="#' + queryString + '"]').click();
                window.scrollTo({
                    top: document.querySelector('.template-where-are-we.map').offsetTop - 50,
                    behavior: "smooth"
                });
            }
            document.querySelectorAll('.template-where-are-we__button').forEach(function (button, index) {
                button.addEventListener("click", function () {
                    sessionStorage.setItem('vue_choice', this.getAttribute('href'));
                });
            });

            if (sessionStorage.getItem('vue_choice')) {
                document.querySelector('.template-where-are-we .nav-tabs > li a[href="' + sessionStorage.getItem('vue_choice') + '"]').click();
            }
        }
    }
}