export default class limitText {
    constructor(el) {
        this.init();
    }
    init() {
        const viewMore = document.querySelectorAll('.view-more'),
            viewLess = document.querySelectorAll('.view-less'),
            moreEstablishment = document.querySelectorAll('.more_establishment');

        if (document.querySelector('.establishment__sectors')) {
            viewMore.forEach(function (button, index) {
                button.addEventListener('click', function () {
                    this.classList.add('d-none');
                    this.parentNode.querySelector('.view-less').classList.remove('d-none');
                    this.parentNode.querySelector('.establishment__sectors').classList.remove('d-none');
                });
            });

            viewLess.forEach(function (button, index) {
                button.addEventListener('click', function () {
                    this.classList.add('d-none');
                    this.parentNode.querySelector('.view-more').classList.remove('d-none');
                    this.parentNode.querySelector('.view-more').classList.add('d-block');
                    this.parentNode.querySelector('.establishment__sectors').classList.add('d-none');
                });
            });
        }

        if (document.querySelector('.double_column')) {
            viewMore.forEach(function (button, index) {
                button.addEventListener('click', function () {
                    this.classList.add('d-none');
                    this.parentNode.querySelector('.view-less').classList.remove('d-none');
                    this.parentNode.querySelector('.double_column__rich-text-start').classList.add('d-none');
                    this.parentNode.querySelector('.double_column__rich-text-end').classList.remove('d-none');
                });
            });

            viewLess.forEach(function (button, index) {
                button.addEventListener('click', function () {
                    this.classList.add('d-none');
                    this.parentNode.querySelector('.view-more').classList.remove('d-none');
                    this.parentNode.querySelector('.view-more').classList.add('d-block');
                    this.parentNode.querySelector('.double_column__rich-text-end').classList.add('d-none');
                    this.parentNode.querySelector('.double_column__rich-text-start').classList.remove('d-none');
                });
            });
        }

        if (document.querySelector('.establishment__push')) {
            viewMore.forEach(function (button, index) {
                button.addEventListener('click', function () {
                    this.classList.add('d-none');
                    this.parentNode.querySelector('.view-less').classList.remove('d-none');
                    this.parentNode.querySelector('.establishment__push-text-start').classList.add('d-none');
                    this.parentNode.querySelector('.establishment__push-text-end').classList.remove('d-none');
                });
            });

            viewLess.forEach(function (button, index) {
                button.addEventListener('click', function () {
                    this.classList.add('d-none');
                    this.parentNode.querySelector('.view-more').classList.remove('d-none');
                    this.parentNode.querySelector('.view-more').classList.add('d-block');
                    this.parentNode.querySelector('.establishment__push-text-end').classList.add('d-none');
                    this.parentNode.querySelector('.establishment__push-text-start').classList.remove('d-none');
                });
            });
        }

        if (moreEstablishment) {
            moreEstablishment.forEach(function (button, index) {
                button.addEventListener('click', function () {
                    this.classList.toggle('active');
                    this.parentNode.querySelector('.training__choice_establishment').classList.toggle('active');
                });
            });
        }
    }
}