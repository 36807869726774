import Scrollbar from "smooth-scrollbar";
import {MarkerClusterer} from "@googlemaps/markerclusterer";

export default class Map {
    constructor() {
        this.init();
    }

    init() {
        const $map = document.getElementById("map"),
            $themePath = document.getElementById("site-footer").getAttribute('data-theme-path'),
            stylesCustomization = [
                {
                    "featureType": "administrative",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi.attraction",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi.business",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi.business",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi.business",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi.government",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi.school",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi.school",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                }
            ];

        if ($map) {
            if (document.body.classList.contains('home') || document.body.classList.contains('page-template-master-page')) {
                Scrollbar.init(document.querySelector('#js-scrollbar'), {
                    alwaysShowTracks: true
                });
            }

            let map,
                locations;

            // Configuration de la MAP
            map = new google.maps.Map($map, {
                zoom: 6,
                scrollwheel: false,
                center: new google.maps.LatLng(0, 0),
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: stylesCustomization,
                streetViewControl: false,
                fullscreenControl: false,
                mapTypeControl: false,
            });

            locations = JSON.parse($map.getAttribute('data-locations'));

            let bounds = new google.maps.LatLngBounds(),
                allMarkers = [],
                activeWindow;

            // Ajout des markers à la MAP
            for (const location in locations) {

                // Création du marker
                const latLng = new google.maps.LatLng(locations[location]['lat'], locations[location]['lng']),
                    icon = {
                        url: $themePath + "/web/dist/img/icons/pin.svg",
                        scaledSize: new google.maps.Size(41, 41),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(0, 0)
                    },
                    activeIcon = {
                        url: $themePath + "/web/dist/img/icons/pin-active.svg",
                        scaledSize: new google.maps.Size(75, 75),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(20, 20)
                    },
                    marker = new google.maps.Marker({
                        position: latLng,
                        map: map,
                        icon: icon,
                        customInfo: locations[location]
                    }),
                    id = locations[location]["id"],
                    city = locations[location]["city"],
                    cp = locations[location]["cp"],
                    title = locations[location]["title"],
                    link = locations[location]["link"],
                    level_studies = locations[location]["level_studies"],
                    infowindow = new google.maps.InfoWindow({
                        maxWidth: 300,
                        pixelOffset: new google.maps.Size(0, 0)
                    });

                google.maps.event.addListener(marker, 'click', infoCallback(infowindow, marker));

                function infoCallback(content, marker) {
                    return function () {
                        const content = `<div class="tooltip-marker">
                                        <span class="title text-orange fw-bold mb-3 fs-18 text-uppercase">` + title + `</span>
                                        <span class="city">` + city + `</span>
                                        <span class="cp text-blue mb-3">` + cp + `</span>
                                        <span class="level-studies mb-3">` + level_studies + `</span>
                                        <a href="` + link + `" class="btn btn-link fs-16" style="outline: 0;">Découvrir l'école<span class="icon d-inline-flex ms-35"><svg xmlns="http://www.w3.org/2000/svg" width="14.396" height="14.281" viewBox="0 0 14.396 14.281"><g id="_01_FlecheNav" data-name="01_FlecheNav" transform="translate(5.033 15.5) rotate(-135)"><g id="Groupe_3443" data-name="Groupe 3443" transform="translate(-0.697 -0.697)"><path id="Tracé_1056" data-name="Tracé 1056" d="M0,0V9.655" transform="translate(5.118 -0.001)" fill="none" stroke="#f6922a" stroke-linecap="square" stroke-linejoin="round" stroke-width="2"/><path id="Tracé_1057" data-name="Tracé 1057" d="M10.073.161,5.117,5.117,0,0" transform="translate(0 6.002)" fill="none" stroke="#f6922a" stroke-linecap="square" stroke-linejoin="bevel" stroke-width="2"/></g></g></svg></span></a>
                                    </div>`;
                        if (activeWindow != null) activeWindow.close();
                        infowindow.setContent(content);
                        infowindow.open(map, marker);
                        activeWindow = infowindow;

                        google.maps.event.addListener(map, "click", function () {
                            for (let i = 0; i < allMarkers.length; i++) {
                                allMarkers[i].setIcon(icon);
                            }
                            infowindow.close();
                        });

                        google.maps.event.addListener(infowindow, 'closeclick', function () {
                            for (let i = 0; i < allMarkers.length; i++) {
                                allMarkers[i].setIcon(icon);
                            }
                        });

                        for (let i = 0; i < allMarkers.length; i++) {
                            allMarkers[i].setIcon(icon);
                        }
                        this.setIcon(activeIcon);

                        // On enlève la classe active sur tous les établissements
                        for (let el of document.querySelectorAll('.establishments .establishment')) el.classList.remove('active');

                        // On affiche ceux dont l'établissement ID est correct
                        for (let el of document.querySelectorAll(".establishments .establishment-id-" + id)) {
                            el.classList.add('active');
                            const scrollbarEstablishments = Scrollbar.init(document.querySelector('#js-scrollbar'), {
                                alwaysShowTracks: true
                            });
                            scrollbarEstablishments.scrollTo(0, el.offsetTop, 600);
                        }
                    };
                }

                // On ajoute ce marker au bond pour permettre un centrage par rapport aux markers
                bounds.extend(latLng);
                allMarkers.push(marker);
            }

            new MarkerClusterer({map:map, markers:allMarkers});

            // On vient centrer nos markers
            if (allMarkers.length === 1) {
                map.setCenter(bounds.getCenter());
                map.setZoom(6);
            } else {
                map.fitBounds(bounds);
            }
        }
    }
}