import {tns} from 'tiny-slider';
import 'tiny-slider/dist/tiny-slider.css';

export default class Carousel {
    constructor(el) {
        this.init();
    }

    init() {
        const jsSlider = document.querySelector('.js-slider');
        if (jsSlider) {
            tns({
                container: jsSlider,
                items: 1,
                loop: true,
                gutter: 30,
                edgePadding: 60,
                nav: false,
                mouseDrag: true,
                responsive: {
                    800: {
                        items: 2
                    },
                    1025: {
                        items: 3
                    }
                },
                prevButton: '.slider__slider-nav .slider-prev',
                nextButton: '.slider__slider-nav .slider-next'
            });
        }

        const jsProjectsSlider = document.querySelector('.js-projects-slider');
        if (jsProjectsSlider) {
            tns({
                container: jsProjectsSlider,
                items: 1,
                loop: true,
                gutter: 30,
                edgePadding: 60,
                nav: false,
                mouseDrag: true,
                responsive: {
                    800: {
                        items: 2
                    },
                    1025: {
                        items: 3
                    }
                },
                prevButton: '.projects-slider__slider-nav .slider-prev',
                nextButton: '.projects-slider__slider-nav .slider-next'
            });
        }

        const jsSliderTrainings = document.querySelector('.js-slider-trainings');
        if (jsSliderTrainings) {
            tns({
                container: jsSliderTrainings,
                items: 1,
                loop: true,
                gutter: 60,
                edgePadding: 60,
                nav: false,
                mouseDrag: true,
                responsive: {
                    1441: {
                        gutter: 80,
                        edgePadding: 185,
                    }
                },
                prevButton: '.slider-trainings__slider-nav .slider-prev',
                nextButton: '.slider-trainings__slider-nav .slider-next'
            });
        }

        const jsSliderJobs = document.querySelector('.js-slider-jobs');
        if (jsSliderJobs) {
            tns({
                container: jsSliderJobs,
                items: 1,
                loop: true,
                gutter: 60,
                edgePadding: 60,
                nav: false,
                mouseDrag: true,
                responsive: {
                    1441: {
                        gutter: 80,
                        edgePadding: 185,
                    }
                },
                prevButton: '.slider-jobs__slider-nav .slider-prev',
                nextButton: '.slider-jobs__slider-nav .slider-next'
            });
        }

        if (window.matchMedia("(max-width: 767px)").matches) {
            const jsSliderMobileFoundersMembers = document.querySelector('.js-slider-mobile-founders-members');
            if (jsSliderMobileFoundersMembers) {
                tns({
                    container: jsSliderMobileFoundersMembers,
                    items: 1,
                    loop: true,
                    center: true,
                    gutter: 80,
                    edgePadding: 100,
                    nav: false,
                    controls: false,
                    mouseDrag: true
                });
            }
        }

        const jsSliderEstablishmentTestimonials = document.querySelector('.js-slider-establishment-testimonials');
        if (jsSliderEstablishmentTestimonials) {
            tns({
                container: jsSliderEstablishmentTestimonials,
                items: 1,
                loop: true,
                nav: false,
                mouseDrag: true,
                prevButton: '.establishment-testimonials__slider-nav .slider-prev',
                nextButton: '.establishment-testimonials__slider-nav .slider-next'
            });
        }

        const jsSliderTrainingTestimonials = document.querySelector('.js-slider-training-testimonials');
        if (jsSliderTrainingTestimonials) {
            tns({
                container: jsSliderTrainingTestimonials,
                items: 1,
                loop: true,
                nav: false,
                mouseDrag: true,
                prevButton: '.training-testimonials__slider-nav .slider-prev',
                nextButton: '.training-testimonials__slider-nav .slider-next'
            });
        }

        const jsSliderEstablishmentOthers = document.querySelector('.js-slider-establishment-others');
        if (jsSliderEstablishmentOthers) {
            tns({
                container: jsSliderEstablishmentOthers,
                items: 1,
                loop: true,
                gutter: 30,
                nav: false,
                mouseDrag: true,
                responsive: {
                    800: {
                        items: 2
                    },
                    1025: {
                        items: 3
                    }
                },
                prevButton: '.establishment-others__slider-nav .slider-prev',
                nextButton: '.establishment-others__slider-nav .slider-next'
            });
        }
    }
}