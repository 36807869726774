import $ from 'jquery';
import 'selectric/public/selectric.css';
import 'selectric/public/jquery.selectric.min';

export default class Select {
    constructor(el) {
        this.init();
    }

    init() {
        if (document.body.classList.contains('home')) {
            const select = document.getElementById("form-search-iam-page"),
                select2 = document.getElementById("form-search-iam-page-2"),
                buttonSearch = document.getElementById("go-to-iam-page"),
                buttonSearchMobile = document.getElementById("go-to-iam-page-mobile");
            let dataUrl = buttonSearch.dataset.url;

            select.addEventListener("change", function () {
                dataUrl = this.value;
                buttonSearch.disabled = false;
                buttonSearchMobile.disabled = false;
            });

            select2.addEventListener("change", function () {
                dataUrl = this.value;
                buttonSearch.disabled = false;
                buttonSearchMobile.disabled = false;
            });

            buttonSearch.addEventListener("click", function () {
                location.href = dataUrl;
            });

            buttonSearchMobile.addEventListener("click", function () {
                location.href = dataUrl;
            });

            window.onload = () => {
                select.selectedIndex = '0';
                select2.selectedIndex = '0';
            }
        }

        const $form = $('.form-search');
        if ($form.length > 0) {
            const $filtersM = $form.find('.select.multiple');
            $filtersM.selectric({
                nativeOnMobile: false,
                forceRenderBelow: true,
                preventWindowScroll: false,
                maxHeight: 350,
                multiple: {
                    separator: ', ',       // Type: String.             Description: Items separator.
                    keepMenuOpen: true,    // Type: Boolean.            Description: Close after an item is selected.
                    maxLabelEntries: false // Type: Boolean or Integer. Description: Max selected items do show.
                },
            });
        }

        if (document.body.classList.contains('page-template-contact')) {
            if (document.querySelector('.wpcf7-select[name="menu-903"]')) {
                const urlParams = new URLSearchParams(window.location.search);
                let queryString = urlParams.get('object');
                if (queryString) {
                    document.querySelector('.wpcf7-select[name="menu-903"]').querySelector("option[value='" + queryString + "']").selected = true;
                }
            }

            if (document.querySelector('.wpcf7-select[name="menu-286"]')) {
                const urlParams = new URLSearchParams(window.location.search);
                let queryString = urlParams.get('i-am');

                if (queryString) {
                    document.querySelector('.wpcf7-select[name="menu-286"]').querySelector("option[value='" + queryString + "']").selected = true;
                    document.querySelector('.wpcf7-select[name="menu-286"]').click();
                }
            }

            if (document.querySelector('.wpcf7-select[name="select-establishments"]')) {
                const urlParams = new URLSearchParams(window.location.search);
                let queryString = urlParams.get('establishment');

                if (queryString) {
                    document.querySelector('.wpcf7-select[name="select-establishments"]').querySelector("option[value='" + queryString + "']").selected = true;
                }
            }
        }

        // Suppression des filtres
        let filterItems = document.querySelectorAll('.selected-filters__item');

        filterItems.forEach(function (item) {
            item.addEventListener("click", function () {
                let itemId = this.dataset.id;
                document.getElementById('form-filter-' + itemId).selected = false;
                document.getElementById('filters').submit();
            });
        });

    }
}