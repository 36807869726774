import {gsap} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import marquee from 'vanilla-marquee';

gsap.registerPlugin(ScrollTrigger);

export default class Animations {
    constructor(el) {
        this.init();
    }

    init() {
        if (document.getElementById("marquee")) {
            const myScroller = new marquee(document.getElementById("marquee"), {
                delayBeforeStart: 0,
                duplicated: true,
                duration: 15000,
                startVisible: true
            });
        }

        setTimeout(function () {
            document.querySelectorAll('h1.field__title').forEach(title => {
                title.classList.add('active');
            });

            document.querySelectorAll('#site-header-home .layouts .layout-square__shadow').forEach(layout => {
                layout.classList.add('active');
            });
        }, 1000);

        /*document.querySelectorAll('.layout-square__shadow').forEach(layout => {
            layout.onmouseover = function () {
                this.classList.add('active');
            };

            layout.onmouseout = function () {
                this.classList.remove('active');
            };
        });*/

        if (document.querySelector('#site-header-home')) {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: "#site-header-home",
                    start: "top top",
                    end: "bottom top",
                    scrub: true
                }
            });

            gsap.utils.toArray(".parallax").forEach(layer => {
                const depth = layer.dataset.depth,
                    movement = -(layer.offsetHeight * depth);
                tl.to(layer, {y: movement, ease: "none"}, 0);
            });
        }

        gsap.utils.toArray(".section-parallax").forEach((section, i) => {
            gsap.to(".parallax-square", {
                yPercent: -100,
                ease: "none",
                scrollTrigger: {
                    trigger: section,
                    start: "top center",
                    end: "bottom top",
                    scrub: true
                }
            });
        });

        document.querySelectorAll('.field__link').forEach(item => {
            const string = item.textContent.split(" "),
                first_line = string.slice(0, 2).join(" "),
                second_line = string.slice(3, 6).join(" ");
            let display_string;

            if (string.length > 3) {
                display_string = "<span>" + first_line + "</span><span>" + second_line + "</span>";
                item.innerHTML = display_string;
            }
        });
    }
}