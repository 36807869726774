import Map from './components/map';
import Carousel from './components/carousel';
import Animations from "./components/animations";
import Viewport from "./components/viewport";
import Video from "./components/video";
import Select from "./components/select";
import Tabs from "./components/tabs";
import limitText from "./components/limit-text";
import Mobile from "./components/mobile";

// initialize scripts
document.addEventListener("DOMContentLoaded", function () {
    if (document.body.classList.contains('home') || document.body.classList.contains('page-template-master-page') || document.body.classList.contains('page-template-where-are-we')) {
        new Map();
    }
    new limitText();
    new Select();
    new Carousel();
    new Animations();
    new Viewport();
    new Video();
    new Tabs();
    new Mobile();
});