export default class Mobile {
    constructor(el) {
        this.init();
    }

    init() {
        const body = document.body,
            burgerMenu = document.getElementById('burger-menu'),
            menuItemHasChildren = document.querySelectorAll('.menu-item-has-children'),
            siteNavigationTopMobile = document.getElementById('site-navigation-top__mobile');

        burgerMenu.addEventListener('click', function () {
            body.classList.toggle('open-menu');
            this.classList.toggle('open');
            siteNavigationTopMobile.classList.toggle('open');
        }, false);

        menuItemHasChildren.forEach(function (item) {
            item.addEventListener('click', function (e) {
                //e.preventDefault();
                this.classList.toggle('active');
            }, false);
        });
    }
}