import Plyr from 'plyr';
import 'plyr/dist/plyr.css'

export default class Video {
    constructor(el) {
        this.init();
    }

    init() {
        const jsPlayer = document.querySelectorAll('.js-player');
        if (jsPlayer) {
            Array.from(jsPlayer).map(p => {
                let video = new Plyr(p, {
                    controls: ['play-large']
                });
                video.poster = p.getAttribute('data-plyr-poster');
            });
        }
    }
}