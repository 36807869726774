import {gsap} from "gsap";

export default class Viewport {
    constructor(el) {
        this.init();
    }

    init() {
        const config = {
            root: null
        };

        const config2 = {
            root: null,
            threshold: 0.95
        };

        const observer = new IntersectionObserver(entries => {
            // Loop over the entries
            entries.forEach(entry => {
                // If the element is visible
                if (entry.isIntersecting) {
                    entry.target.classList.add('is-active');
                }
            });
        }, config);

        const observer2 = new IntersectionObserver(entries => {
            // Loop over the entries
            entries.forEach(entry => {
                // If the element is visible
                if (entry.isIntersecting) {
                    entry.target.classList.add('is-active');
                }
            });
        }, config2);

        const animFromLeft = document.querySelectorAll('.anim-from-left'),
            animFromRight = document.querySelectorAll('.anim-from-right'),
            sectionParallax = document.querySelectorAll('.section-parallax'),
            animScrollDeco = document.querySelectorAll('.anim-scroll-deco'),
            fieldTitleGlobal = document.querySelectorAll('.field__title-global');

        animFromLeft.forEach(item => {
            observer.observe(item);
        });

        animFromRight.forEach(item => {
            observer.observe(item);
        });

        fieldTitleGlobal.forEach(item => {
            observer.observe(item);
        });

        animScrollDeco.forEach(item => {
            observer2.observe(item);
        });

        sectionParallax.forEach(item => {
            observer.observe(item);
        });
    }
}